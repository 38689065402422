import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';
import letter from '../assets/icons/envelope.svg';

const Thanks = () => {
  return (
    <>
      <NavPages />
      <section>
        <div className='container tc'>
          <div className='row'>
            <div className='col-lg-7 ma'>
              <div className='s-50'></div>
              <h3 className='title c-theme'>Thank You for Your Payment!</h3>
              <div className='s-50'></div>
              <img
                src={letter}
                width={24}
                height={24}
                alt='icon'
                className='mx-3'
              ></img>
              <div className='s-30'></div>
              <p>
                Great news! Your payment details have been successfully
                dispatched to your email. Kindly check your inbox for
                transaction specifics. Additionally, keep an eye out for an
                invitation to our Slack workspace, where we'll be coordinating
                the creative journey together.
              </p>
              <p className='title-sub py-4'>Thank you for choosing us!</p>
              <div className='s-40'></div>
            </div>
          </div>
          <div className='s-100'></div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export { Thanks };
