import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Pricing } from './pages/Pricing';
import { Privacy } from './pages/Privacy';
import { Terms } from './pages/Terms';
import { Refund } from './pages/Refund';
import { Cookie } from './pages/Cookie';
import { Thanks } from './pages/Thanks';
import { Error } from './pages/Error';
import { PaymentError } from './pages/PaymentError';

import './styles/custom.sass';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='settings/plans' element={<Pricing />} />
        <Route path='cookie-policy' element={<Cookie />} />
        <Route path='privacy-policy' element={<Privacy />} />
        <Route path='terms-of-use' element={<Terms />} />
        <Route path='refund-policy' element={<Refund />} />
        <Route path='thank-you' element={<Thanks />} />
        <Route path='payment-failed' element={<PaymentError />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export { App };
