import React from 'react';

import { Nav } from '../components/Nav';
import { Intro } from './parts/Intro';
import { Crafted } from './parts/Crafted';
import { Cta } from './parts/Cta';
import { FAQ } from './parts/FAQ';
import { Footer } from '../components/Footer';

const Home = () => {
  return (
    <>
      <Nav />
      <Intro />
      <Crafted />
      <Cta />
      <FAQ />
      <Footer />
    </>
  );
};

export { Home };
