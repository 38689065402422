import React from 'react'

import logo from '../logo.svg'

const NavPages = () => {
  return (
    <header className="w-100">
      <div className="container">
        <header className="flex jcsb aic h-10 py-5">
          <h1 id="logo">
            <a href="/" aria-label="emagweb - Home">
              <img src={logo} width={187} height={32} alt="emagweb" />
            </a>
          </h1>
          <div className="flex jcfe aic mobile">
            <a href="/" className="mx-4" aria-label="emagweb">Home</a>
          </div>
        </header>
      </div>
    </header>
  )
}

export { NavPages }
