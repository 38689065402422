import React from 'react';
import visa from '../assets/plans/visa.svg';
import master from '../assets/plans/mastercard.svg';
import { Price } from '../components/cards/Price';

const color = ['#BE728A', '#A16A8D', '#A27EA4'];

const Pricing = () => {
  return (
    <>
      <div className='canvas'></div>
      <section id='admin'>
        <div className='s-60'></div>
        <div className='top-cta'>
          <h3 className='title tc c-theme'>Unlock Exclusive Deals!</h3>
          <div className='s-10'></div>
          <h4 className='title-sub tc c-white'>
            Explore Our Price List and Discover Exclusive Offers!
          </h4>
          <div className='s-10'></div>
          <p className='w-80 ma tc c-white'>
            To access our full range of services, simply choose your desired
            package, and you'll receive further instructions via email.
            <br />
            No registration required – just swift access to unbeatable deals.
            Start your journey now!
          </p>
        </div>
        <div className='s-40'></div>
        <div className='container'>
          <div className='card-deck mb-3'>
            <div className='row'>
              <div className='col-lg-4 px-3'>
                <Price
                  title='Standard'
                  value={'700'}
                  desc='Suitable for solo entrepreneurs or small businesses'
                  info="Provides all the essentials for your business's solid visual foundation, ready for future scaling."
                  custom={false}
                  link='https://secure.wayforpay.com/button/b33b1cb3ab8a6'
                  color={color[2]}
                  footer={color[3]}
                />
              </div>
              <div className='col-lg-4 px-3'>
                <Price
                  title='Professional'
                  value={'2000'}
                  desc='For startups and medium-sized businesses'
                  info='Includes all Standard features plus additional premium services for enhanced business growth.'
                  custom={false}
                  link='https://secure.wayforpay.com/button/b9c3061311c3c'
                  color={color[1]}
                  footer={color[4]}
                />
              </div>
              <div className='col-lg-4 px-3'>
                <Price
                  title='Enterprise'
                  value='Custom'
                  desc='For large companies with their own unique needs'
                  info='Comprises everything in the Professional package with added exclusive enterprise-level solutions.'
                  custom={true}
                  link='https://calendly.com/emagweb-ceo/emagweb'
                  color={color[0]}
                  footer={color[5]}
                ></Price>
              </div>
            </div>
          </div>
          <div className='s-30'></div>
          <div className='s-m-40'></div>
          <div className='methods tc'>
            <h5 className='c-white'>We Accept the Payment Methods</h5>
            <div className='flex jcc aic'>
              <a href='https://visa.com/' aria-label='Visa' className='mx-3'>
                <img
                  src={visa}
                  className='payment-icons-img'
                  width='131'
                  height='40'
                  alt='Visa'
                ></img>
              </a>
              <a
                href='https://mastercard.com/'
                aria-label='MasterCard'
                className='mx-3'
              >
                <img
                  src={master}
                  className='payment-icons-img'
                  width='69'
                  height='40'
                  alt='MasterCard'
                ></img>
              </a>
            </div>
            <div className='s-80'></div>
            <div className='s-m-40'></div>
            <a href='/' className='tc w-100 c-white mt-5 mb-0'>
              Home
            </a>
          </div>
        </div>
        <div className='s-80'></div>
      </section>
    </>
  );
};

export { Pricing };
