import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';

const Privacy = () => {
  return (
    <>
      <NavPages />
      <section>
        <div className='s-60'></div>
        <div className='m-40'></div>
        <div className='m-20'></div>
        <div className='m-40'></div>
        <div className='m-20'></div>
        <div className='m-40'></div>
        <div className='container page-wrapper'>
          <p>
            <strong>PRIVACY POLICY</strong>
          </p>
          <p>
            <em>Last updated November 23, 2023</em>
          </p>
          <p>
            This privacy notice for Emagweb ("<strong>we</strong>," "
            <strong>us</strong>," or "<strong>our</strong>"), describes how and
            why we might collect, store, use, and/or share ("
            <strong>process</strong>") your information when you use our
            services ("<strong>Services</strong>"), such as when you:
          </p>
          <ul>
            <li>
              Visit our website at{' '}
              <a href='https://emagweb.site'>https://emagweb.site</a>, or any
              website of ours that links to this privacy notice
            </li>
            <li>
              Engage with us in other related ways, including any sales,
              marketing, or events
            </li>
          </ul>
          <p>
            <strong>Questions or concerns? </strong>Reading this privacy notice
            will help you understand your privacy rights and choices. If you do
            not agree with our policies and practices, please do not use our
            Services. If you still have any questions or concerns, please
            contact us at{' '}
            <a href='mailto:admin@emagweb.site'>admin@emagweb.site</a>.
          </p>
          <p>
            <strong>SUMMARY OF KEY POINTS</strong>
          </p>
          <p>
            <strong>
              <em>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our table
                of contents below to find the section you are looking for.
              </em>
            </strong>
          </p>
          <p>
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with us and the Services,
            the choices you make, and the products and features you use. Learn
            more about personal information you disclose to us.
          </p>
          <p>
            <strong>Do we process any sensitive personal information?</strong>{' '}
            We do not process sensitive personal information.
          </p>
          <p>
            <strong>Do we receive any information from third parties?</strong>{' '}
            We do not receive any information from third parties.
          </p>
          <p>
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Learn more about how we process
            your information.
          </p>
          <p>
            <strong>
              In what situations and with which parties do we share personal
              information?
            </strong>{' '}
            We may share information in specific situations and with specific
            third parties. Learn more about when and with whom we share your
            personal information.
          </p>
          <p>
            <strong>How do we keep your information safe?</strong> We have
            organizational and technical processes and procedures in place to
            protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information. Learn more about how we
            keep your information safe.
          </p>
          <p>
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information. Learn more about
            your privacy rights.
          </p>
          <p>
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by submitting a{' '}
            <a href='https://app.termly.io/notify/91785e1b-0a67-4f6d-bdb6-a44448b6b085'>
              data subject access request
            </a>
            , or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>
          <p>
            Want to learn more about what we do with any information we collect?
            Review the privacy notice in full.
          </p>
          <p>
            <strong>TABLE OF CONTENTS</strong>
          </p>
          <p>1. WHAT INFORMATION DO WE COLLECT?</p>
          <p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
          <p>
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
            INFORMATION?
          </p>
          <p>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
          <p>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
          <p>6. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
          <p>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
          <p>8. DO WE COLLECT INFORMATION FROM MINORS?</p>
          <p>9. WHAT ARE YOUR PRIVACY RIGHTS?</p>
          <p>10. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
          <p>11. DO WE MAKE UPDATES TO THIS NOTICE?</p>
          <p>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
          <p>
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </p>
          <p>
            <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
          </p>
          <p>
            <strong>Personal information you disclose to us</strong>
          </p>
          <p>
            <strong>
              <em>
                In Short: We collect personal information that you provide to
                us.
              </em>
            </strong>
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you express an interest in obtaining information about us or
            our products and Services, when you participate in activities on the
            Services, or otherwise when you contact us.
          </p>
          <p>
            <strong>Personal Information Provided by You.</strong> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
          </p>
          <ul>
            <li>Email address</li>
            <li>Name</li>
            <li>Surname</li>
            <li>Phone number</li>
            <li>Company name</li>
          </ul>
          <p>
            <strong>Sensitive Information.</strong> We do not process sensitive
            information.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <p>
            <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
          </p>
          <p>
            <strong>
              <em>
                In Short: We process your information to provide, improve, and
                administer our Services, communicate with you, for security and
                fraud prevention, and to comply with law. We may also process
                your information for other purposes with your consent.
              </em>
            </strong>
          </p>
          <p>
            <strong>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </strong>
          </p>
          <ul>
            <li>
              <strong>
                To deliver and facilitate delivery of services to the user.
              </strong>
              We may process your information to provide you with the requested
              service.
            </li>
            <li>
              <strong>
                To respond to user inquiries/offer support to users.{' '}
              </strong>
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </li>
            <li>
              <strong>To send administrative information to you. </strong>We may
              process your information to send you details about our products
              and services, changes to our terms and policies, and other similar
              information.
            </li>
            <li>
              <strong>To fulfill and manage your orders.</strong> We may process
              your information to fulfill and manage your orders, payments,
              returns, and exchanges made through the Services.
            </li>
            <li>
              <strong>To enable user-to-user communications. </strong>We may
              process your information if you choose to use any of our offerings
              that allow for communication with another user.
            </li>
            <li>
              <strong>
                To save or protect an individual's vital interest.
              </strong>{' '}
              We may process your information when necessary to save or protect
              an individual’s vital interest, such as to prevent harm.
            </li>
          </ul>
          <p>
            <strong>
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </strong>
          </p>
          <p>
            <strong>
              <em>
                In Short: We only process your personal information when we
                believe it is necessary and we have a valid legal reason (i.e.,
                legal basis) to do so under applicable law, like with your
                consent, to comply with laws, to provide you with services to
                enter into or fulfill our contractual obligations, to protect
                your rights, or to fulfill our legitimate business interests.
              </em>
            </strong>
          </p>
          <p>
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>
          <ul>
            <li>
              <strong>Consent. </strong>We may process your information if you
              have given us permission (i.e., consent) to use your personal
              information for a specific purpose. You can withdraw your consent
              at any time. Learn more about withdrawing your consent.
            </li>
            <li>
              <strong>Performance of a Contract.</strong> We may process your
              personal information when we believe it is necessary to fulfill
              our contractual obligations to you, including providing our
              Services or at your request prior to entering into a contract with
              you.
            </li>
            <li>
              <strong>Legal Obligations.</strong> We may process your
              information where we believe it is necessary for compliance with
              our legal obligations, such as to cooperate with a law enforcement
              body or regulatory agency, exercise or defend our legal rights, or
              disclose your information as evidence in litigation in which we
              are involved.
            </li>
            <li>
              <strong>Vital Interests.</strong> We may process your information
              where we believe it is necessary to protect your vital interests
              or the vital interests of a third party, such as situations
              involving potential threats to the safety of any person.
            </li>
          </ul>
          <p>
            <strong>
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </strong>
          </p>
          <p>
            <strong>
              <em>
                In Short: We may share information in specific situations
                described in this section and/or with the following third
                parties.
              </em>
            </strong>
          </p>
          <p>
            We may need to share your personal information in the following
            situations:
          </p>
          <ul>
            <li>
              <strong>Business Transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
            <li>
              <strong>When we use Google Maps Platform APIs.</strong> We may
              share your information with certain Google Maps Platform APIs
              (e.g., Google Maps API, Places API).
            </li>
          </ul>
          <p>
            <strong>
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </strong>
          </p>
          <p>
            <strong>
              <em>
                In Short: We may use cookies and other tracking technologies to
                collect and store your information.
              </em>
            </strong>
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>
          <p>
            <strong>6. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
          </p>
          <p>
            <strong>
              <em>
                In Short: We keep your information for as long as necessary to
                fulfill the purposes outlined in this privacy notice unless
                otherwise required by law.
              </em>
            </strong>
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements).
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
          <p>
            <strong>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
          </p>
          <p>
            <strong>
              <em>
                In Short: We aim to protect your personal information through a
                system of organizational and technical security measures.
              </em>
            </strong>
          </p>
          <p>
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Although we will do our best to
            protect your personal information, transmission of personal
            information to and from our Services is at your own risk. You should
            only access the Services within a secure environment.
          </p>
          <p>
            <strong>8. DO WE COLLECT INFORMATION FROM MINORS?</strong>
          </p>
          <p>
            <strong>
              <em>
                In Short: We do not knowingly collect data from or market to
                children under 18 years of age.
              </em>
            </strong>
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at{' '}
            <a href='mailto:admin@emagweb.site'>admin@emagweb.site</a>.
          </p>
          <p>
            <strong>9. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
          </p>
          <p>
            <strong>
              <em>
                In Short: In some regions, such as the European Economic Area
                (EEA), United Kingdom (UK), and Switzerland, you have rights
                that allow you greater access to and control over your personal
                information. You may review, change, or terminate your account
                at any time.
              </em>
            </strong>
          </p>
          <p>
            In some regions (like the EEA, UK, and Switzerland), you have
            certain rights under applicable data protection laws. These may
            include the right (i) to request access and obtain a copy of your
            personal information, (ii) to request rectification or erasure;
            (iii) to restrict the processing of your personal information; (iv)
            if applicable, to data portability; and (v) not to be subject to
            automated decision-making. In certain circumstances, you may also
            have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section "HOW CAN YOU CONTACT US
            ABOUT THIS NOTICE?" below.
          </p>
          <p>
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>
          <p></p>
          <p>
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your{' '}
            <a href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
              Member State data protection authority
            </a>{' '}
            or{' '}
            <a href='https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/'>
              UK data protection authority
            </a>
            .
          </p>
          <p>
            If you are located in Switzerland, you may contact the{' '}
            <a href='https://www.edoeb.admin.ch/edoeb/en/home.html'>
              Federal Data Protection and Information Commissioner
            </a>
            .
          </p>
          <p>
            <strong>
              <span>Withdrawing your consent:</span>
            </strong>{' '}
            If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at any
            time. You can withdraw your consent at any time by contacting us by
            using the contact details provided in the section "HOW CAN YOU
            CONTACT US ABOUT THIS NOTICE?" below.
          </p>
          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, will it affect the processing
            of your personal information conducted in reliance on lawful
            processing grounds other than consent.
          </p>
          <p>
            <strong>
              <span>Cookies and similar technologies:</span>
            </strong>{' '}
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Services.
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at
            <a href='mailto:admin@emagweb.site'>admin@emagweb.site</a>.
          </p>
          <p>
            <strong>10. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
          </p>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
          <p>
            <strong>11. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
          </p>
          <p>
            <strong>
              <em>
                In Short: Yes, we will update this notice as necessary to stay
                compliant with relevant laws.
              </em>
            </strong>
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <p>
            <strong>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
          </p>
          <p>
            If you have questions or comments about this notice, you may email
            us at
            <a href='mailto:admin@emagweb.site'>admin@emagweb.site</a>
          </p>
          <p>
            <strong>
              13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </strong>
          </p>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please fill out and submit a{' '}
            <a href='https://app.termly.io/notify/91785e1b-0a67-4f6d-bdb6-a44448b6b085'>
              data subject access request
            </a>
            .
          </p>
        </div>
        <div className='s-100'></div>
        <div className='m-40'></div>
      </section>
      <Footer />
    </>
  );
};

export { Privacy };
