import React from 'react'

const Cta = () => {
  return (
    <section id="price">
      <div className="container text-center">
        <div className="s-100"></div>
        <div className="s-100"></div>
        <div className="s-m-40"></div>
        <div className="s-m-40"></div>
        <h3 className="title-cta c-white w-80 m-100 ma">
          Unlock limitless design possibilities with our monthly subscription and enjoy free support
          throughout!
        </h3>
        <div className="s-40"></div>
        <p className="title-price c-white">
          2000€<span>/mo.</span>
        </p>
        <div className="s-40"></div>
        <a href="/settings/plans/" className="button">
          Order Now
        </a>
        <div className="s-m-40"></div>
        <div className="s-m-40"></div>
        <div className="s-100"></div>
        <div className="s-100"></div>
      </div>
    </section>
  )
}

export { Cta }
