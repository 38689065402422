import React from 'react';
import { NavLink } from 'react-router-dom';

import idea from '../../assets/pics/idea.webp';
import face from '../../assets/pics/face.webp';

const Intro = () => {
  return (
    <section id='top'>
      <div className='container tc'>
        <div className='s-80'></div>
        <h2 className='title-intro'>
          Your <img src={idea} alt='Ideas' /> Ideas
          <br />
          Our <img src={face} alt='Solutions' /> Solutions
        </h2>
        <div className='s-20'></div>
        <p className='title-sub w-60 m-100 ma'>
          Emagweb is a service that provides access to creative experts who will
          elevate the professional level of your company through high-quality
          design.
        </p>
        <div className='s-20'></div>
        <a
          href='https://calendly.com/emagweb-ceo/emagweb'
          target='blank'
          className='button mx-3'
        >
          Book a Demo
        </a>
        <NavLink to='settings/plans/' className='button dark mx-3'>
          Order Now
        </NavLink>
        <div className='s-100'></div>
        <div className='s-20'></div>
      </div>
    </section>
  );
};

export { Intro };
