import React from 'react'
import rocket from '../../assets/plans/rocket.svg'
import lamp from '../../assets/plans/lamp.svg'
import icon from '../../assets/plans/card.svg'
import slack from '../../assets/icons/slack.svg'
import { FcOk } from 'react-icons/fc'


const Price = (props) => {

  return (
    <div className='card'>
      <div className='card-header px-3 py-3' style={{backgroundColor: props.color}}>
        <h4 className='my-3 c-white tc mx-4'>Benefits of a 7-day<br /><span>Money Back Guarantee</span></h4>
      </div>
      <div className='card-body pt-2'>
        <h1 className='card-title flex aic jcsb p-3'>
          <span style={{color: props.color}}>{props.title}</span>
          {props.custom ? (
            <span style={{color: props.color}}><small>Custom  </small></span>
          ) : (
            <span style={{color: props.color}}>€{props.value}<small>/mo.</small></span>
          )}
        </h1>
        <p className='card-sub flex jcc aic' style={{color: props.color}}>{props.desc}</p>
          {
            props.value === '700' ?
            <div className='flex flex-list'>
              <ul className='list-unstyled mt-3 mx-2 px-2'>
                <li><FcOk className='prl' size={18} />UI/UX Design</li>
                <li><FcOk className='prl' size={18} />Web Design</li>
                <li><FcOk className='prl' size={18} />Banners & Logos</li>
                <li><FcOk className='prl' size={18} />Flyers</li>
                <li><FcOk className='prl' size={18} />Presentations</li>
                <li><img className='prl last' src={slack} width={16} height={16} alt={'Slack'} />Slack Integration</li>
              </ul>
            </div> :
            props.value === '2000' ?
            <div className='flex flex-list'>
              <ul className='list-unstyled mt-3 mx-2 px-2'>
                <li><FcOk className='prl' size={18} />App Design</li>
                <li><FcOk className='prl' size={18} />Package Design</li>
                <li><FcOk className='prl' size={18} />Product Design</li>
                <li><FcOk className='prl' size={18} />Email Design</li>
                <li><FcOk className='prl' size={18} />Print Design</li>
                <li><img className='prl last' src={slack} width={16} height={16} alt={'Slack'} />Slack Integration</li>
              </ul>
            </div> :
            <div className='flex flex-list'>
              <ul className='list-unstyled mt-3 mx-2 px-2'>
                <li><FcOk className='prl' size={18} />Corporate Identity Development</li>
                <li><FcOk className='prl' size={18} />Internal Corporate Design</li>
                <li><FcOk className='prl' size={18} />Social Media Design</li>
                <li><FcOk className='prl' size={18} />Branding Consultation</li>
                <li><FcOk className='prl' size={18} />Customized Solutions</li>
                <li><img className='prl last' src={slack} width={16} height={16} alt={'Slack'} />Slack Integration</li>
              </ul>
            </div>
          }
        <p className='card-mini px-4' style={{color: props.color}}>{props.info}</p>
        <div className='grdv'>
          <div className='flex aic pr-3 pl-1'>
            <img src={rocket} alt={'icon'} width={48} height={48} />
            <h5 className='py-3 px-3 card-subtitle inf' style={{color: props.color}}>TODAY</h5>
          </div>
          <p className='card-text shift'>
            Our seven-day plan allows you to try out our services. During this
            period, you will have access to basic features and functionalities.
          </p>
          <div className='flex aic pr-3 pl-1'>
            <img src={lamp} alt={'icon'} width={48} height={48} />
            <h5 className='py-3 px-3 card-subtitle inf' style={{color: props.color}}>IN 5 DAYS</h5>
          </div>
          <p className='card-text shift'>
            The perfect way to see how efficiently and seamlessly you can
            collaborate with our team.
          </p>
          <div className='flex aic pr-3 pl-1'>
            <img src={icon} alt={'icon'} width={48} height={48} />
            <h5 className='py-3 px-3 card-subtitle inf' style={{color: props.color}}>IN 7 DAYS</h5>
          </div>
          <p className='card-text shift'>
            After the completion of the seven-day plan, you can decide whether
            you want to choose one of our paid monthly plans to access
            additional features and benefits.
          </p>
          <div className="grdv-in"></div>
        </div>
        <div className="s-20"></div>
        <div className='card-footer'>
          <div className='info px-3'>
            <div className='mt-2 tc'>
              <p className='card-subtitle my-0 py-0'>
                <strong style={{color: props.color}}>
                  {props.custom ? (
                    <span style={{color: props.color}}>Custom</span>
                  ) : (
                    <span style={{color: props.color}}><strong>€{props.value}</strong> <small>Per Month</small></span>
                  )}
                </strong>
              </p>
              <p className='card-text my-0 py-0'>
                <strong style={{color: props.color}}>7-Day Money Back Guarantee</strong>
              </p>
            </div>
          </div>
          <div className="w-100 tc">
            <a href={props.link} target='blank' className='button pricing my-3 c-white'>
              {props.custom ? (
                  <span>Book a Demo</span>
                ) : (
                  <span>Buy Now</span>
                )}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Price }
