import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';

const Refund = () => {
  return (
    <>
      <NavPages />
      <section>
        <div className='s-60'></div>
        <div className='m-40'></div>
        <div className='m-20'></div>
        <div className='m-40'></div>
        <div className='m-20'></div>
        <div className='m-40'></div>
        <div className='container page-wrapper'>
          <p>
            <strong>Refund policy</strong>
          </p>
          <p>
            <em>Our Refund Policy was last updated 07.12.2023 </em>
          </p>
          <p>
            Thank you for shopping at{' '}
            <a href='https://emagweb.site/'>https://emagweb.site/</a>
          </p>
          <p>
            <strong>Definitions</strong>
          </p>
          <p>For the purposes of this Refund Policy:</p>
          <p>● “Orders” means a request by You to purchase Services from Us.</p>
          <p>
            ● “You” means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </p>
          <p>
            The following terms are applicable for any <strong>Services</strong>{' '}
            that You purchased with Us.
          </p>
          <p>
            We have a 7-day Refund policy, which means you have 7 days after
            ordering your <strong>Service</strong> to request a Refund.
          </p>
          <p>
            To be eligible for a Refund you'll need the receipt or proof of
            purchase.
          </p>
          <p>
            In order to exercise Your right of cancellation, You must inform Us
            of your decision by means of a clear statement. To start a Refund,
            you can contact us at{' '}
            <a href='mailto:admin@emagweb.site'>admin@emagweb.site</a>.
          </p>
          <p>
            Please get in touch if you have questions or concerns about{' '}
            <strong>Service</strong> you have ordered.
          </p>
          <p>
            Unfortunately, we cannot accept Refunds on <strong>Services</strong>{' '}
            with discounts or gift cards.
          </p>
          <p>
            European Union 3 day cooling off period Notwithstanding the above,
            if <strong>Service</strong> is being ordered into the European
            Union, you have the right to cancel or Refund your order within 3
            days for any reason and without justification.
          </p>
          <p>
            <strong>Refunds:</strong> We will notify you once we’ve received
            your request and inspected your Refund to let you know if the refund
            was approved or not.
          </p>
          <p>
            If approved, you’ll be automatically refunded on your original
            payment method within 10 business days.
          </p>
          <p>
            Please remember it can take some time for your bank or credit card
            company to process and post the refund too.
          </p>
          <p>
            If more than 15 business days have passed since we’ve approved your
            Refund, please contact us at{' '}
            <a href='mailto:admin@emagweb.site'>admin@emagweb.site</a>.
          </p>
          <p>
            You can always contact us for any Refund questions at{' '}
            <a href='mailto:admin@emagweb.site'>admin@emagweb.site</a>.
          </p>
        </div>
        <div className='s-100'></div>
        <div className='m-40'></div>
      </section>
      <Footer />
    </>
  );
};

export { Refund };
