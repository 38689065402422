import React from 'react';

const Footer = () => {
  const year = new Date();
  return (
    <footer id='contact' className='w-100 h-20 flex jcc aic bg-dark'>
      <div className='container'>
        <div className='s-10'></div>
        <div className='row'>
          <div className='col-lg-6 pt-2'>
            <p className='c-light px-2'>
              <a href='/'>emagweb</a> @ {year.getFullYear()} - All Rights
              Reserved.
            </p>
          </div>
          <div className='col-lg-6 tr pt-2'>
            <a href='/privacy-policy' className='c-light'>
              Privacy Policy
            </a>
            <a href='/terms-of-use' className='c-light'>
              Terms Of Use
            </a>
            <a href='/refund-policy' className='c-light'>
              Refund Policy
            </a>
            <a href='/cookie-policy' className='c-light'>
              Cookie Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
