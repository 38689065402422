import React from 'react';
import { NavPages } from '../components/NavPages';
import { Footer } from '../components/Footer';

const PaymentError = () => {
  return (
    <>
      <NavPages />
      <section>
        <div className='container tc'>
          <div className='row'>
            <div className='col-lg-7 ma'>
              <div className='s-50'></div>
              <h3 className='title c-theme'>Payment Unsuccessful</h3>
              <div className='s-50'></div>
              <p>
                We regret to inform you that your payment attempt was
                unsuccessful. Our team is dedicated to ensuring a smooth and
                secure transaction process, and we understand that encountering
                payment issues can be frustrating.
              </p>
              <div className='s-40'></div>
              <h4 className='title-sub c-dark pb-4'>
                Here are a few steps to help resolve the issue:
              </h4>
              <ul className='tl mx-2 px-3'>
                <li>
                  <strong>Check Your Payment Information:</strong> Verify that
                  the credit/debit card details provided are accurate, including
                  the card number, expiration date, and CVV code.
                </li>
                <li>
                  <strong>Sufficient Funds:</strong> Ensure that your account
                  has sufficient funds to cover the transaction amount.
                </li>
                <li>
                  <strong>Payment Gateway Issues:</strong> Occasionally, payment
                  gateways may experience temporary disruptions. Please wait a
                  moment and attempt the payment again.
                </li>
              </ul>
              <div className='s-40'></div>
              <p>
                If the issue persists or if you have any questions, feel free to
                contact our support team at{' '}
                <a href='mailto:admin@emagweb.site' className='c-marine'>
                  admin@emagweb.site
                </a>
                . We are here to assist you and resolve any issues you may
                encounter.
              </p>
              <p className='title-sub py-4'>
                Thank you for your understanding.
              </p>
              <div className='s-40'></div>
              <a href='/settings/plans' className='button'>
                Back to Payment Page
              </a>
            </div>
          </div>
          <div className='s-100'></div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export { PaymentError };
