import React from 'react';
import Slider from 'react-slick';

import pic1 from '../../assets/statistics/d001.png';
import pic2 from '../../assets/statistics/d002.png';
import pic3 from '../../assets/statistics/d003.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Crafted = () => {
  const slides = [
    {
      pic: pic1,
    },
    {
      pic: pic2,
    },
    {
      pic: pic3,
    },
  ];

  const Gallery = () => {
    var settings = {
      autoplay: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 3000,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className='mx-5 gallery r-32'>
        <Slider {...settings}>
          {slides.map((screen, i) => (
            <img
              width={'100%'}
              height={400}
              src={screen.pic}
              alt={'pic'}
              key={i}
              style={{ objectFit: 'contain', margin: '0 20px' }}
            />
          ))}
        </Slider>
      </div>
    );
  };

  return (
    <section id='crafted' className='py-3 py-md-5'>
      <div className='s-100'></div>
      <div className='container tc'>
        <h3 className='title'>
          Guided by a Design and Development Expert Our Team’s
        </h3>
        <div className='s-20'></div>
        <p className='title-sub w-60 m-100 ma'>
          Under the leadership of a seasoned CXO with over two decades of
          experience in design and development, our team thrives on innovation
          and expertise
        </p>
        <div className='s-40'></div>
        <div className='w-80 m-100 ma'>
          <span className='button cat m-2'>Branding</span>
          <span className='button cat m-2'>UI/UX Design</span>
          <span className='button cat m-2'>Presentations</span>
          <span className='button cat m-2'>Web Design</span>
          <span className='button cat m-2'>Banners</span>
          <span className='button cat m-2'>Flyers</span>
          <span className='button cat m-2'>App Design</span>
          <span className='button cat m-2'>Logos</span>
          <span className='button cat m-2'>Product Design</span>
          <span className='button cat m-2'>Email</span>
          <span className='button cat m-2'>Print Design</span>
        </div>
        <div className='s-40'></div>
        <div className='w-80 m-100 ma'>
          <Gallery />
        </div>
        <div className='s-40'></div>
        <p className='w-60 m-100 ma'>
          This graph showcases the remarkable journey of our mentor,
          illustrating the growth in website creations, significant achievements
          in marketplace ratings, and a profound impact on training the next
          generation of IT professionals. Witness the evolution of excellence
          that drives our projects towards unparalleled success
        </p>
        <div className='s-40'></div>
        <div className='s-100'></div>
      </div>
    </section>
  );
};

export { Crafted };
